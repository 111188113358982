import copy from '../copy.png';
import styles from './QRCodeText.module.css';
import React from 'react';

export type QRCodeTextProps = {
    flexoUrl: string;
};

export default function QRCodeText(props: QRCodeTextProps) {
    return (
            <div>
                <div className="flex my-2">
                    <input id="text" value={props.flexoUrl} type="text" readOnly className={styles.textInput} />
                    <button onClick={() => {navigator.clipboard.writeText(props.flexoUrl)}}><img src={copy} width="24" alt="kopieren"/></button>
                </div>
            </div>
        );
}