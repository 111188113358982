import "../../node_modules/react-grid-layout/css/styles.css";
import "../../node_modules/react-resizable/css/styles.css";
import GridLayout from "react-grid-layout";
import React, {useState} from "react";

export type SelectedModule = {
    index: string;
    x: number;
    y: number;
    w: number;
    h: number;
    type: string;
    rotation: number;
    iconImages: string[];
};

export type GridLayoutProps = {
    open: boolean;
    modules: SelectedModule[];
    onLayoutChange(layout: SelectedModule[]): void;
    onSelectModule(moduleId: string): void;
};

const biggestMostRightModule = (modules: SelectedModule[]) => {
    const mostRightModules = modules.map((item) => {
        return {...item, rightSideX: item.x + (item.rotation % 180 === 0 ? item.h : item.w)}
    }).sort((a,b) => b.rightSideX - a.rightSideX);
    return mostRightModules[0];
}

const biggestMostBottomModule = (modules: SelectedModule[]) => {
    const mostBottomModules = modules.map((item) => {
        return {...item, downSideY: item.y + (item.rotation % 180 === 0 ? item.w : item.h)}
    }).sort((a,b) => b.downSideY - a.downSideY);
    return mostBottomModules[0];
}

const MyFirstGrid = ({
                         open,
                         modules,
                         onLayoutChange,
                         onSelectModule,
                     }: GridLayoutProps) => {
    const layout = modules.map((m) => {
        return {
            i: m.index,
            x: m.x,
            y: m.y,
            w: (m.rotation / 90) % 2 === 1 ? m.w : m.h,
            h: (m.rotation / 90) % 2 === 1 ? m.h : m.w,
            isResizable: false,
        };
    });

    const [activeModule, setActiveModule] = useState("");

    const setActive = (moduleId: string) => {
        setActiveModule(moduleId);
        onSelectModule(moduleId);
    }

    const moduleElements = modules.map((m) => {
            return <div
                id={m.index}
                key={m.index}
                className={
                    "border bg-gray-300 border-gray-700 overflow-hidden bg-cover rot-" +
                    m.rotation
                }
                style={{
                    backgroundImage:
                        'url("' + m.iconImages[Math.abs(m.rotation / 90)] + '")',
                }}
                onClick={() => setActive(m.index)}
                onTouchStart={() => setActive(m.index)}
            >
                {activeModule === m.index
                    ? <div style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        backgroundColor: "rgba(0, 0, 255,0.5)",
                        width: "100%",
                        height: "100%"
                    }}/>
                    : <></>}
            </div>
        }
    );

    if (moduleElements.length === 0) {
        return (
            <div
                className={
                    "border border-gray-300 rounded bg-gray-100 p-2 mt-4 mr-3 text-center text-sm"
                }
            >
                Verschieben Sie die einzelnen Module auf dieser Fläche um Ihre Küche
                zusammenzustellen.
            </div>
        );
    }

    const rulerWidth = (biggestMostRightModule(modules).rightSideX - Math.min(...modules.map((item) => item.x))) * 10;
    const rulerHeight = (biggestMostBottomModule(modules).downSideY - Math.min(...modules.map((item) => item.y))) * 10;
    const minModuleX = Math.min(...modules.map((item) => item.x)) * 10;
    const minModuleY = Math.min(...modules.map((item) => item.y)) * 10;

    return (
        open ?
        <div style={{position: "relative", padding: "40px"}}>
            <div style={{height: "30px",  width: `${rulerWidth}px`, fontSize: '0.9em', background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 60%, rgba(173,146,113,1) 61%, rgba(173,146,113,1) 62%, rgba(255,255,255,0) 63%, rgba(255,255,255,0) 100%)", position: "absolute", zIndex: 1000, top: `calc(20px)`, left: `calc(${minModuleX}px + 49px)`, textAlign: 'center'}}>{Intl.NumberFormat('de').format(parseFloat((rulerWidth/60*65).toFixed(1)))} cm</div>
            <div style={{padding: '10px 0 0 0', transformOrigin: '0 0', transform: 'rotate(90deg)', height: "30px", width: `${rulerHeight}px`, top: `calc(${minModuleY}px + 50px)`, left: `51px`, textAlign: 'center', verticalAlign: 'bottom', fontSize: '0.9em', background: "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 60%, rgba(173,146,113,1) 61%, rgba(173,146,113,1) 62%, rgba(255,255,255,0) 63%, rgba(255,255,255,0) 100%)", position: "absolute", zIndex: 1000}}>{Intl.NumberFormat('de').format(parseFloat((rulerHeight/60*65).toFixed(1)))} cm</div>
            <GridLayout
                className="border border-gray-300 bg-gray-100 mx-2 my-2"
                layout={layout}
                cols={60}
                rowHeight={10}
                maxRows={36}
                width={600}
                margin={[0, 0]}
                preventCollision={true}
                compactType={null}
                onLayoutChange={(layout1) => {
                    const modules: SelectedModule[] = layout1.map((l) => {
                        return {
                            index: l.i,
                            x: l.x,
                            y: l.y,
                            w: l.w,
                            h: l.h,
                            type: "",
                            rotation: 0,
                            iconImages: [],
                        };
                    });
                    onLayoutChange(modules);
                }}
            >
                {moduleElements}
            </GridLayout>
            </div>
            : <></>
    );
};

export default MyFirstGrid;
