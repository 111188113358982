import Modal from "react-modal";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimes, faUndoAlt} from "@fortawesome/free-solid-svg-icons";

export type HelpModalProps = {
    modalIsOpen: boolean;
    setOpen: any;
}

const customStyles = {
    content: {
        top: "20px",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        width: "75%",
        maxWidth: "1000px",
        transform: "translate(-50%, 0)",
        zIndex: 400,
    },
};

export default function HelpModal(props: HelpModalProps) {

    const afterOpenModal = () => {
    };

    const closeModal = () => {
        props.setOpen(false);
    };

    return <Modal
        isOpen={props.modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName={"test-overlay"}
    >
        <div>
            <p className="text-left p-2"><b>Herzlich Willkommen im Flexocube Konfigurator!</b></p>

            <p className="text-left p-2">Und so funktioniert&apos;s:</p>
            <p className="text-left p-2">Über <b>Modulauswahl</b> fügst Du Module hinzu.</p>

            <p className="text-left p-2">
                Im <b>2D-Konfigurator</b> siehst Du die Module in der Draufsicht. <b>Nur dort kannst Du die Module verschieben.</b><br/>
                Hast Du ein Modul ausgewählt, kannst Du es über die Schaltflächen
                <FontAwesomeIcon className="mx-2" size={"1x"} icon={faUndoAlt}/> drehen,
                <FontAwesomeIcon className="mx-2" size={"1x"} icon={faPlus}/> duplizieren oder
                <FontAwesomeIcon className="mx-2" size={"1x"} icon={faTimes}/> löschen.</p>

            <p className="text-left p-2 mb-4">
                In der <b>3D-Ansicht</b> kannst Du Deine Küche aus verschiedenen Perspektiven betrachten, indem Du in die
                3D-Ansicht klickst und in verschiedene Richtungen ziehst. Zum Zoomen hältst Du die [Strg]-Taste gedrückt und scrollst
                mit Deinem Mausrad auf- (Vergrößern) oder abwärts (Verkleinern) oder nutzt üblichen Funktionen Deines mobilen Endgerätes.
            </p>
            <p className="text-left p-2 mb-4">
                Über <b>Teilen</b> kannst Du die von Dir konfigurierte Küche anderen Personen weitergeben. Dazu einfach den QR Code abscannen oder
                den angezeigten Link versenden.
            </p>
            <p className="text-left p-2 mb-4">
                Über einen Klick auf <b>Zum Warenkorb hinzufügen</b> landet Deine Küche direkt in unserem Shop-Warenkorb, wo Du weitere Konfigurationen
                wie den Fußtyp (Rollen oder Standfuß) und Lieferung ohne oder mit Aufbau wählen kannst.
            </p>
            <p className="text-left p-2 mb-4">
                Und nun viel Spaß bei der Nutzung! Dein Flexocube Team.
            </p>
            <div className="flex-grow"/>
            <div className="flex justify-end">
                <button
                    className="rounded closeButton px-4 py-2 text-white cursor-pointer "
                    onClick={() => {
                        closeModal();
                    }}
                    onTouchEnd={() => {
                        closeModal();
                    }}
                >
                    Schließen
                </button>
            </div>
        </div>
    </Modal>
}