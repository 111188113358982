
import React, {useEffect, useState} from "react";
import {SelectedModule} from "../organisms/GridCanvas";
import env from "../config";

export type ModulListProps = {
    open: boolean,
    modules: any[],
    selectedModules: SelectedModule[]
};
export type ArticleListProps = {
    articleNumber: string,
    name: string,
    price: number
};
export type PositionListProps = {
    articleNumber: string,
    name: string,
    quantity: number,
    price: number
};
const ArticleList = ({open, modules,selectedModules}: ModulListProps) => {
    const [selectedItems, setSelectedItems] = useState<PositionListProps[]>([]);
    const totalSum = selectedItems.reduce((sum, article) => sum + (article.price * article.quantity), 0);
    const [email, setEmail] = useState<string>("");

    const handleEmail = async () => {
        let data = await fetch(
            env.REACT_APP_BASE_URL + "/share/shareLink/request",
            {
                method: "POST",
                body: JSON.stringify({
                    validUntil: "2024-08-09",
                    name: "",
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            }
        ).then((result) => result.json());

        // Make POST request here
        let request = JSON.stringify({
            email: email,
            totalsum: totalSum,
            code: data.randomIdentifier,
            articles: selectedItems
        });
        let configuration = {
            name: "last",
            date: new Date().toISOString(),
            modules: selectedModules,
            shareLink: data.randomIdentifier,
        };

        localStorage.setItem("savedConfigs", JSON.stringify(configuration));
        const config = {
            modules: selectedModules,
            shareLink: data.randomIdentifier,
        }
        await fetch(env.REACT_APP_BASE_URL + "share/shareLink/addConfiguration", {
            method: "POST",
            body: JSON.stringify({
                content: JSON.stringify(config),
                randomIdentifier: data.randomIdentifier,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((result) => result.json())
            .then((data) => {})
            .catch((error) => console.log(error));
        fetch(env.REACT_APP_BASE_URL + 'share/sendmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: request
        })
            .then(response => {
                if (response.ok) {
                    console.log('Email sent successfully');
                    // Handle success, e.g., show a success message
                } else {
                    console.log(response.status)
                    console.log(response.statusText)
                    console.error('Failed to send Email');
                    // Handle failure, e.g., show an error message
                }
            })
            .catch(error => {
                console.error('Error occurred while email sending:', error);
                // Handle error, e.g., show an error message
            });
        setEmail("");
        alert("Email versendet");
    };
    useEffect(() => {
        let items: any[] = [];
        selectedModules.forEach(selectedModule => {
            const moduleData = modules.find(m => m.moduleId === selectedModule.type);
            if (moduleData) {
                moduleData.articles.forEach((article:ArticleListProps) => {
                    const existingItemIndex = items.findIndex(item => item.articleNumber === article.articleNumber);
                    if (existingItemIndex !== -1) {
                        // If the item already exists, update its quantity
                        items[existingItemIndex].quantity++;
                    } else {
                        // Otherwise, add a new item to the array
                        items.push({
                            articleNumber: article.articleNumber,
                            name: article.name,
                            quantity: 1,
                            price: article.price
                        });
                    }
                });
            }
        });
        setSelectedItems(items);
    }, [selectedModules, modules]);
    return open ?
        <div>
            <div className="text-2xl ml-5 mt-5 mb-5">Bestellliste</div>
            <div style={{textAlign: 'left'}}>
                <table className="table-auto" style={{marginLeft: "20px"}}>
                    <thead>
                    <tr>
                        <th className="w-1/8" style={{borderBottom: "1px solid black", textAlign: 'left'}}>Pos.</th>
                        <th className="w-1/3" style={{borderBottom: "1px solid black", textAlign: 'left'}}>Bezeichnung</th>
                        <th className="w-1/5" style={{borderBottom: "1px solid black", textAlign: 'right'}}>Anzahl</th>
                        <th className="w-1/5" style={{borderBottom: "1px solid black", textAlign: 'right'}}>Einzelpreis</th>
                        <th className="w-1/5" style={{borderBottom: "1px solid black", textAlign: 'right', paddingRight: "20px"}}>Gesamt</th>
                    </tr>
                    </thead>
                    <tbody>
                    {selectedItems.map((article: PositionListProps, index) => (
                        <tr key={article.articleNumber}>
                            <td style={{padding: '8px', textAlign: 'left'}}>{index+1}</td>
                            <td style={{padding: '8px', textAlign: 'left'}}>{article.name}<br/>{article.articleNumber}</td>
                            <td style={{padding: '8px', textAlign: 'right'}}>{article.quantity}</td>
                            <td style={{padding: '8px', textAlign: 'right'}}>
                                {article.price.toLocaleString()},00 €
                            </td>
                            <td style={{padding: '8px', textAlign: 'right', paddingRight: "20px"}}>
                                {(article.price * article.quantity).toLocaleString()},00 €
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={2} style={{borderTop: '1px solid black', padding: '8px', textAlign: 'left'}}><b>Gesamt:</b></td>
                        <td style={{borderTop: '1px solid black', padding: '8px'}}></td>
                        <td style={{borderTop: '1px solid black', padding: '8px'}}></td>
                        <td style={{
                            borderTop: '1px solid black',
                            padding: '8px',
                            textAlign: 'right',
                            paddingRight: "20px"
                        }}><b>{totalSum.toLocaleString()},00 €</b>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="mt-5 ml-2 text-sm">Preise inkl. MwSt. und zzgl. Lieferung und Aufbau</div>
            <div className="flex p-2">
                <input
                    type="email"
                    placeholder="Geben Sie Ihre E-Mail an"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="rounded-l-lg p-2 w-1/2 mr-2 border border-gray-300"
                />
                <button onClick={handleEmail}
                        className="addModule border rounded text-white p-2">
                    E-Mail senden
                </button>
            </div>


        </div>


        : <></>
}

export default ArticleList;