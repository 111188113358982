import React, {useEffect, useState} from 'react';
import ModulListItem from "../organisms/ModulListItem";

export type ModulListProps = {
    open: boolean,
    modules: any[],
    create: any
};

const ModuleList = ({open, modules, create}: ModulListProps) => {
    const [isGrillActivated, setIsGrillActivated] = useState(false);
    const [isKuehlschrankActivated, setIsKuehlschrankActivated] = useState(false);
    const [isSpueleActivated, setIsSpueleActivated] = useState(false);
    const [isSchrankActivated, setIsSchrankActivated] = useState(false);
    const [isSonstigeActivated, setIsSonstigeActivated] = useState(false);
    const [isBreite975Activated, setIsBreite975Activated] = useState(false);
    const [isBreite650Activated, setIsBreite650Activated] = useState(false);
    const [isSpritzschutzActivated, setIsSpritzschutzActivated] = useState(false);
    const [isGassteckdoseActivated, setIsGassteckdoseActivated] = useState(false);
    const [isBarerhoeungActivated, setIsBarerhoeungActivated] = useState(false);
    const [filteredModules, setFilteredModules] = useState(modules);

    useEffect(() => {
        const updateFilteredModules = () => {
            let filtered = modules;
            let hasType: boolean = false;
            let hasBreite: boolean = false;
            filtered = modules.filter(module => {
                let meetsCriteria = false;
                if (isGrillActivated && module.tags.some((tag:string) => tag.toLowerCase().includes('typ:grill'))) {
                    meetsCriteria = true;
                }
                if (isKuehlschrankActivated && module.tags.some((tag:string) => tag.toLowerCase().includes('typ:kühlschrank'))) {
                    meetsCriteria = true;
                }
                if (isSpueleActivated && module.tags.some((tag:string) => tag.toLowerCase().includes('typ:spüle'))) {
                    meetsCriteria = true;
                }
                if (isSchrankActivated && module.tags.some((tag:string) => tag.toLowerCase().includes('typ:schrank'))) {
                    meetsCriteria = true;
                }
                if (isSonstigeActivated && module.tags.some((tag:string) => tag.toLowerCase().includes('typ:sonstige'))) {
                    meetsCriteria = true;
                }
                return meetsCriteria;
            });
            if(isGrillActivated || isKuehlschrankActivated || isSpueleActivated || isSchrankActivated || isSonstigeActivated){
                hasType = true;
            }
            if(isBreite650Activated || isBreite975Activated){
                hasBreite = true;
            }
            if (isBreite650Activated) {
                if(hasType){
                    filtered = filtered.filter(module => module.tags.some((tag: string) => tag.toLowerCase().includes('breite:650mm')));
                } else {
                    filtered = modules.filter(module => module.tags.some((tag: string) => tag.toLowerCase().includes('breite:650mm')));
                }

            }
            if (isBreite975Activated) {
                if(hasType){
                    filtered = filtered.filter(module => module.tags.some((tag: string) => tag.toLowerCase().includes('breite:975mm')));
                } else {
                    filtered = modules.filter(module => module.tags.some((tag: string) => tag.toLowerCase().includes('breite:975mm')));
                }
            }
            if(isSpritzschutzActivated){
                if(hasType || hasBreite){
                    filtered = filtered.filter(module => module.tags.some((tag: string) => tag.toLowerCase().includes('ausstattung:spritzschutzwand')));
                } else {
                    filtered = modules.filter(module => module.tags.some((tag: string) => tag.toLowerCase().includes('ausstattung:spritzschutzwand')));
                }
            }
            if(isBarerhoeungActivated){
                if(hasType || hasBreite){
                    filtered = filtered.filter(module => module.tags.some((tag: string) => tag.toLowerCase().includes('ausstattung:barerhöhung')));
                } else {
                    filtered = modules.filter(module => module.tags.some((tag: string) => tag.toLowerCase().includes('ausstattung:barerhöhung')));
                }
            }
            if(isGassteckdoseActivated){
                if(hasType || hasBreite){
                    filtered = filtered.filter(module => module.tags.some((tag: string) => tag.toLowerCase().includes('ausstattung:gassteckdose')));
                } else {
                    filtered = modules.filter(module => module.tags.some((tag: string) => tag.toLowerCase().includes('ausstattung:gassteckdose')));
                }
            }

            if (!isGrillActivated && !isKuehlschrankActivated && !isSpueleActivated && !isSchrankActivated && !isSonstigeActivated && !isBreite650Activated && !isBreite975Activated && !isSpritzschutzActivated && !isGassteckdoseActivated && !isBarerhoeungActivated) {
                filtered = modules;
            }

            setFilteredModules(filtered);
        };

        updateFilteredModules();
    }, [isGrillActivated, isKuehlschrankActivated, isSpueleActivated,isSchrankActivated,isSonstigeActivated,isBreite975Activated,isBreite650Activated,isSpritzschutzActivated,isGassteckdoseActivated,isBarerhoeungActivated,modules]);

    const handleGrillToggle = () => {
        setIsGrillActivated(!isGrillActivated);
    };

    const handleKuehlschrankToggle = () => {
        setIsKuehlschrankActivated(!isKuehlschrankActivated);
    };

    const handleSpueleToggle = () => {
        setIsSpueleActivated(!isSpueleActivated);
    };
    const handleSchrankToggle = () => {
        setIsSchrankActivated(!isSchrankActivated);
    };
    const handleSonstigeToggle = () => {
        setIsSonstigeActivated(!isSonstigeActivated);
    };
    const handleBreite975Toggle = () => {
        setIsBreite975Activated(!isBreite975Activated);
    };
    const handleBreite650Toggle = () => {
        setIsBreite650Activated(!isBreite650Activated);
    };
    const handleSpritzschutzToggle = () => {
        setIsSpritzschutzActivated(!isSpritzschutzActivated);
    };
    const handleGassteckdoseToggle = () => {
        setIsGassteckdoseActivated(!isGassteckdoseActivated);
    };
    const handleBarerhoeungToggle = () => {
        setIsBarerhoeungActivated(!isBarerhoeungActivated);
    };


    return (open ?
            <div className={"flex flex-col justify-around text-left cursor-pointer"}>
                <div className="flex flex-row p-2">
                    <div className={"text-center p-2 border border-gray-300 bg-gray-100 mb-4"}>Wählen Sie hier Ihre
                        Outdoor-Küchen Module aus und fügen Sie sie zum Konfigurator hinzu.
                    </div>
                </div>
                <div className={"font-medium leading-6 ml-5"}>Typ</div>
                <div className="flex flex-row p-2">
                    <button
                        className={isKuehlschrankActivated ? "addModule border rounded text-white p-2 ml-3" : "rounded bg-white border infoButton text-white p-2 ml-3"}
                        onClick={handleKuehlschrankToggle}>Kühlschrank
                    </button>
                    <button
                        className={isGrillActivated ? "addModule border rounded text-white p-2 ml-3" : "rounded bg-white border infoButton text-white p-2 ml-3"}
                        onClick={handleGrillToggle}>Grill
                    </button>
                    <button
                        className={isSpueleActivated ? "addModule border rounded text-white p-2 ml-3" : "rounded bg-white border infoButton text-white p-2 ml-3"}
                        onClick={handleSpueleToggle}>Spüle
                    </button>
                    <button
                        className={isSchrankActivated ? "addModule border rounded text-white p-2 ml-3" : "rounded bg-white border infoButton text-white p-2 ml-3"}
                        onClick={handleSchrankToggle}>Schrank
                    </button>
                    <button
                        className={isSonstigeActivated ? "addModule border rounded text-white p-2 ml-3" : "rounded bg-white border infoButton text-white p-2 ml-3"}
                        onClick={handleSonstigeToggle}>Sonstige
                    </button>
                </div>
                <div className={"font-medium leading-6 ml-5"}>Breite</div>
                <div className="flex flex-row p-2">
                    <button
                        className={isBreite975Activated ? "addModule border rounded text-white p-2 ml-3" : "rounded bg-white border infoButton text-white p-2 ml-3"}
                        onClick={handleBreite975Toggle}>975mm
                    </button>
                    <button
                        className={isBreite650Activated ? "addModule border rounded text-white p-2 ml-3" : "rounded bg-white border infoButton text-white p-2 ml-3"}
                        onClick={handleBreite650Toggle}>650mm
                    </button>
                </div>
                <div className={"font-medium leading-6 ml-5"}>Ausstattung</div>
                <div className="flex flex-row p-2">
                    <button
                        className={isSpritzschutzActivated ? "addModule border rounded text-white p-2 ml-3" : "rounded bg-white border infoButton text-white p-2 ml-3"}
                        onClick={handleSpritzschutzToggle}>mit Spritzschutz
                    </button>
                    <button
                        className={isGassteckdoseActivated ? "addModule border rounded text-white p-2 ml-3" : "rounded bg-white border infoButton text-white p-2 ml-3"}
                        onClick={handleGassteckdoseToggle}>mit Gassteckdose
                    </button>
                    <button
                        className={isBarerhoeungActivated ? "addModule border rounded text-white p-2 ml-3" : "rounded bg-white border infoButton text-white p-2 ml-3"}
                        onClick={handleBarerhoeungToggle}>mit Barerhöhung
                    </button>
                </div>
                <hr/>
                {filteredModules.map((module) => (
                    <ModulListItem
                        name={module.name}
                        description={module.description}
                        module={module.moduleId}
                        releaseDate={module.releaseDate}
                        type={module.type}
                        create={create}
                        image={module.previewImage ?? ""}
                        price={module.price}
                        key={module.moduleId}
                        width={module.width}
                        depth={module.depth}
                        tags={module.tags}
                        articles={module.articles}
                    />
                ))}

            </div>
            : <></>
    );
}
export default ModuleList;