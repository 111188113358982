import React from "react";

export type FlexTabProps = {
    text: string;
    onClick: Function;
    active: boolean;
    hover: boolean;
    classes: string;
}

const Tab = ({text, onClick, active, hover, classes}: FlexTabProps) => {
    const classesActive = active
        ? " bg-gray-300 xl:bg-gray-100"
        : " xl:bg-gray-100";

    const classesHover = hover
        ? " sm:hover:bg-gray-200"
        : "";

    return (<h1 className={"p-4 float-left " + classes + classesActive + classesHover}
                onClick={() => onClick()}>{text}</h1>);
};

export default Tab;