import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./pages/App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import Modal from "react-modal";
import env from "./config";

import {
  BrowserRouter as Router,
  Route,
  RouteComponentProps,
} from "react-router-dom";
import { Switch } from "react-router";

Modal.setAppElement("#root");

function loadModulesFromBackend(props: RouteComponentProps) {
  let params: any = props.match.params;
  const identifier = params.id;

  fetch(
    env.REACT_APP_BASE_URL +
      "share/shareLink/findByRandomIdentifier?randomIdentifier=" +
      identifier,
    {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // body data type must match "Content-Type" header
    }
  )
    .then((response) => response.json())
    .then((data) => {
      if (data.content !== undefined) {
        localStorage.setItem("savedConfigs", data.content);
        console.log(data.content);
        window.location.pathname = "/";
      }
    });
  return <div></div>;
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/" component={App} />
        <Route path="/load/:id" component={loadModulesFromBackend} />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
