import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimes, faUndoAlt} from "@fortawesome/free-solid-svg-icons";
import {SelectedModule} from "../organisms/GridCanvas";
import React from "react";
import {ModulListItemProps} from "../organisms/ModulListItem";

export type  ModuleDetails = {
    open: boolean;
    module: SelectedModule | undefined;
    moduleDetails: ModulListItemProps | any;
    onRotate(moduleId: string): void;
    onDelete(moduleId: string): void;
    onDuplicate(moduleId: string): void;
};

const Details = ({open, module, moduleDetails, onRotate, onDelete, onDuplicate}: ModuleDetails) => {

    const onClickDelete = () => {
        if (module && window.confirm('Wollen Sie das Modul wirklich entfernen?')) {
            onDelete(module.index);
        }
    }

    const price = moduleDetails ?
        (moduleDetails.releaseDate * 1000 > new Date().getTime() ? (
            <div className={"font-bold text-red-600 mr-4"}>
                ab{" "}
                {new Intl.DateTimeFormat("de-DE").format(
                    new Date(moduleDetails.releaseDate * 1000)
                )}
            </div>
        ) : (
            <div className={"font-bold text-gray-800 mr-4"}>
                {new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                }).format(moduleDetails.price)}
            </div>
        ))
        : <></>;

    const ModuleDescription = () => {
        return moduleDetails
            ? <div className="flex flex-col xl:flex-row">
                <div className="xl:w-1/2 flex flex-col gap-2 mt-4">
                    <div className="font-bold flex justify-between items-center">
                        <span>{moduleDetails.name}</span>
                    </div>
                    <div >{moduleDetails.description}</div>
                    <div>Breite: {new Intl.NumberFormat("de-DE", {
                        minimumFractionDigits: 1
                    }).format(moduleDetails.width)} cm</div>
                    <div>Tiefe: {new Intl.NumberFormat("de-DE", {
                        minimumFractionDigits: 1
                    }).format(moduleDetails.depth)} cm</div>
                    <div className="flex justify-end">
                        {price}
                    </div>
                </div>
                <div className="xl: w-1/2">
                    <img src={moduleDetails.previewImage} alt="" className="object-fit w-full"/>
                </div>
            </div>
            : <></>;
    }

    if (module && open) {
        return <div className="m-4">
            <span
                onClick={() => onRotate(module.index)}
                className={"m-4 p-4"} title={"drehen"}>
                <FontAwesomeIcon size={"2x"} icon={faUndoAlt}/>
            </span>
            <span
                onClick={() => onDuplicate(module.index)}
                className={"m-4 p-4"} title={"duplizieren"} >
                <FontAwesomeIcon size={"2x"} icon={faPlus}/>
            </span>
            <span
                onClick={() => onClickDelete()}
                className={"m-4 p-4"} title={"entfernen"}>
                <FontAwesomeIcon size={"2x"} icon={faTimes}/>
            </span>
            <ModuleDescription/>
        </div>
    }
    else {
        return <></>;
    }
}
export default Details;