import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader.js";
import { DDSLoader } from "three/examples/jsm/loaders/DDSLoader.js";

export function initializeWorld(canvas) {

  var firstRender = true;

  const renderer = new THREE.WebGLRenderer({antialias: true, canvas});

  const fov = 45;
  const aspect = 2; // the canvas default
  const near = 0.1;
  const far = 300;
  const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
  camera.position.set(-30, 30, 40);

  window.threeDView = {};
  const onProgress = function (xhr) {
    if (xhr.lengthComputable) {
      // const percentComplete = (xhr.loaded / xhr.total) * 100;
    }
  };

  const onError = function (error) {
    console.log(error);
  };

  window.threeDView.createOBJ = function (id, nameID, x, y, z, rot) {
    let r = 2;
    switch (rot) {
      case 0:
        r = -Math.PI / 2;
        break;
      case 90:
        r = -Math.PI / 0.5;
        break;
      case 180:
        r = Math.PI / 2;
        break;
      case 270:
        r = -Math.PI;
        break;
      default:
        r = -Math.PI / 2;
        break;
    }

    const manager = new THREE.LoadingManager();
    manager.addHandler(/\.dds$/i, new DDSLoader());

    new MTLLoader(manager)
        .setPath("../Assets/models/" + nameID + "/")
        .load(nameID + ".mtl", function (materials) {
          materials.preload();

          new OBJLoader(manager)
              .setMaterials(materials)
              .setPath("../Assets/models/" + nameID + "/")
              .load(
                  nameID + ".obj",
                  function (object) {
                    object.position.set(x, z, y);
                    object.scale.set(9.3, 9.3, 9.3);
                    object.name = id;
                    object.rotation.set(0, r, 0);
                    window.scene.add(object);
                    //const event = new Event("build");
                    //window.dispatchEvent(event);
                  },
                  onProgress,
                  onError
              );
        });
  };

  window.threeDView.move = function (id, position) {
    var selectedObject = window.scene.getObjectByName(id);

    if (selectedObject === undefined) {
      return;
    }
    selectedObject.position.set(position.x, 0, position.y);
    var isTurnedAround = false;
    var r = 2;
    switch (position.rotation) {
      case 0:
        r = 2;
        isTurnedAround = false;
        break;
      case 90:
        r = 0.5;
        isTurnedAround = false;
        break;
      case 180:
        r = 2;
        isTurnedAround = true;
        break;
      case 270:
        r = 1;
        isTurnedAround = false;
        break;
      default:
        r = 2;
        break;
    }
    if (isTurnedAround) {
      selectedObject.rotation.set(0, Math.PI / r, 0);
    } else {
      selectedObject.rotation.set(0, -Math.PI / r, 0);
    }
  };

  window.threeDView.remove = function (id) {
    var selectedObject = window.scene.getObjectByName(id);
    window.scene.remove(selectedObject);
  };

  window.threeDView.clear = function () {
    const controls = new OrbitControls(camera, canvas);
    controls.rotateSpeed = 0.2;
    controls.minDistance = 30;
    controls.maxDistance = 100;
    controls.minPolarAngle = 0.5;
    controls.maxPolarAngle = Math.PI / 2;
    controls.target.set(0, 5, 0);
    controls.update();
    window.scene = new THREE.Scene();
    window.scene.background = new THREE.Color("rgb(175, 209, 241)");

    const planeSize = 200;
    const loader = new THREE.TextureLoader();
    const texture = loader.load("Assets/pavement.jpg");
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(6, 6);

    const planeGeo = new THREE.PlaneGeometry(planeSize, planeSize);
    const planeMat = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide,
    });
    const mesh = new THREE.Mesh(planeGeo, planeMat);
    mesh.rotation.x = Math.PI * -0.5;
    window.scene.add(mesh);

    const color = 0xffffff;
    const intensity = 1;
    const light = new THREE.DirectionalLight(color, intensity);
    light.position.set(5, 10, 2);
    window.scene.add(light);
    window.scene.add(light.target);

    const amblight = new THREE.AmbientLight(0xcccccc); // soft white light
    window.scene.add(amblight);

    if (localStorage.getItem('debug_mode')) {
      let geometry = new THREE.BoxGeometry(1, 1, 1);
      let materialRed = new THREE.MeshBasicMaterial({color: 0xff0000});
      let materialBlue = new THREE.MeshBasicMaterial({color: 0x0000ff});
      let materialGreen = new THREE.MeshBasicMaterial({color: 0x00ff00});
      let cube = new THREE.Mesh(geometry, materialBlue);
      let cubeX = new THREE.Mesh(geometry, materialRed);
      cubeX.position.x = 10
      let cubeY = new THREE.Mesh(geometry, materialGreen);
      cubeY.position.z = 10
      window.scene.add(cube);
      window.scene.add(cubeX);
      window.scene.add(cubeY);
    }
  };

  function resizeRendererToDisplaySize(renderer) {
    const canvas = renderer.domElement;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
      renderer.setSize(width, height, false);
    }
    return needResize;
  }

  function render() {
    if (resizeRendererToDisplaySize(renderer)) {
      const canvas = renderer.domElement;
      camera.aspect = canvas.clientWidth / canvas.clientHeight;
      camera.updateProjectionMatrix();
    }
    renderer.render(window.scene, camera);
    if (firstRender) {
      window.dispatchEvent(new Event("threeJS_loaded"));
      firstRender = false;
    }

    requestAnimationFrame(render);
  }

  window.threeDView.clear();
  requestAnimationFrame(render);
}
