import QRCode from "./QrCode";
import styles from "./QRCodePlain.module.css";
import React from "react";

export type QRCodePlainProps = {
  flexoUrl: string;
};

export default function QRCodePlain(props: QRCodePlainProps) {
  return (
    <div className={styles.qrcodeBorder}>
      <div className={"flex flex-col " + styles.qrcodeContent}>
        <QRCode url={props.flexoUrl}></QRCode>
        <div className={styles.subText}>
          Jetzt scannen und in der Smartphone App öffnen
        </div>
      </div>
    </div>
  );
}
