import Modal from "react-modal";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import QRCodeText from '../atoms/QRCodeText';
import QRCodePlain from '../atoms/QRCodePlain';
import React from 'react';

export type HeaderModalProps = {
    modalIsOpen: boolean;
    closeModal: any;
    identifierUrl: any;
};

const customStyles = {
    content: {
        top: "20px",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, 0)",
    },
};



export default function HeaderModal(props: HeaderModalProps) {
    return <Modal
        isOpen={props.modalIsOpen}
        onRequestClose={props.closeModal}
        style={customStyles}
        contentLabel="Example Modal"
    >
        <div className="flex">
            <div className="flex flex-col">
                <div className="flex">
                    <h2 className="nt-bold text-2xl mr-3">Teile Deine Konfiguration mit Deinen Freunden</h2>
                    <FontAwesomeIcon icon={faTimes} className="fas fa-times cursor-pointer" onClick={() => {
                        props.closeModal();
                    }}></FontAwesomeIcon>
                </div>

                <div id="qrcode"></div>
                <QRCodePlain flexoUrl={props.identifierUrl}></QRCodePlain>
                <QRCodeText flexoUrl={props.identifierUrl}></QRCodeText>
                <div className="flex">
                    <a href="https://apps.apple.com/de/app/flexocube/id1628277493"><img src="Download_on_the_App_Store_Badge_DE_RGB_blk_092917.svg" alt="Download im Apple App Store" style={{height: '55px', padding: '7px 7px 0 0'}}/></a>
                <a href='https://play.google.com/store/apps/details?id=de.romeis.flexocube.app&utm_source=konfigurator&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Jetzt bei Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png' style={{width: '160px'}}/></a>
                </div>
                <div className="flex-grow"></div>
            </div>
        </div>
    </Modal>
}