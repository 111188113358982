import React, {useEffect, useRef} from 'react';
import {initializeWorld} from "./three"

export default function World(props: { className?: string, style?: any }) {
    const canvas = useRef<HTMLCanvasElement | null>(null)
    useEffect(() => {
        if (canvas.current) initializeWorld(canvas.current)
    }, []);

    return <canvas {...props} id="c" ref={canvas}/>
}