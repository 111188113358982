import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPlus} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import React, { useState } from "react";

export type ModulListItemProps = {
  name: string;
  description: string;
  module: string;
  create: any;
  image: string;
  price: number;
  releaseDate: number;
  type: string;
  width: number;
  depth: number;
  articles: ModulArticle[];
  tags: string[];
};

export type ModulArticle = {
  articleNumber: string;
  name: string;
  price: number
}

export default function ModulListItem(props: ModulListItemProps) {
  const customStyles = {
    content: {
      top: "20px",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "75%",
      maxWidth: "1000px",
      transform: "translate(-50%, 0)",
      zIndex: 400,
    },
  };
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const afterOpenModal = () => {};

  const closeModal = () => {
    setIsOpen(false);
  };

  const truncate = (input: string, length: number) =>
    input.length > length ? `${input.substring(0, length)}...` : input;

  const price =
    props.releaseDate * 1000 > new Date().getTime() ? (
      <div className={"font-bold text-red-600 mr-4"}>
        ab{" "}
        {new Intl.DateTimeFormat("de-DE").format(
          new Date(props.releaseDate * 1000)
        )}
      </div>
    ) : (
      <div className={"font-bold text-gray-800 mr-4"}>
        {new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
        }).format(props.price)}
      </div>
    );

  return (
    <div className="w-full">
      <div className="border-b border-gray-300 p-2 flex">
        <img
          src={props.image}
          alt=""
          className="object-fill h-24 w-24 border-gray-300"
        />
        <div className="flex flex-col flex-grow px-2">
          <div className="font-bold flex justify-between items-center">
            <span>{props.name}</span>
          </div>
          <div>{truncate(props.description, 100)}</div>
          <div className="flex flex-row">{props.tags.map((tag) => (
              <div style={{ backgroundColor: '#e8e8e8', padding: '5px', margin: '5px', borderRadius: '5px',color:"gray"}}>{tag.replace('Typ:', '').replace('Breite:','').replace("Ausstattung:","")}</div>
          ))}</div>
          <div className="flex justify-end items-center">
            {price}
            <div
              className="rounded addModule border text-white cursor-pointer px-2 py-1 mr-2"
              onClick={() => {
                props.create(props.module);
              }}
            >
              <span className="mr-2">Hinzufügen</span>
              <FontAwesomeIcon icon={faPlus} />
            </div>
            <div
              className="rounded bg-white border infoButton px-2 py-1 cursor-pointer"
              onClick={() => {
                openModal();
              }}
              onTouchEnd={() => {
                openModal();
              }}
            >
              <span className="mr-2 hidden">Infos</span>
              <FontAwesomeIcon icon={faInfoCircle} />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName={"test-overlay"}
      >
        <div className="flex">
          <div className="w-1/2">
            <img src={props.image} alt="" className="object-fit w-full" />
          </div>
          <div className="w-1/2 px-2 flex flex-col">
            <h2 className="font-bold text-2xl">{props.name}</h2>
            <div>{props.description}</div>
            <div className="flex flex-col">
              <div className="flex flex-col xl:flex-row">
                <div className="xl:w-1/2 flex flex-col gap-2 mt-4">
                  <div className="xl:w-1/2">Breite: {new Intl.NumberFormat("de-DE", {
                    minimumFractionDigits: 1
                  }).format(props.width)} cm<br/>
                    Tiefe: {new Intl.NumberFormat("de-DE", {
                      minimumFractionDigits: 1
                    }).format(props.depth)} cm
                  </div>
                </div>
                <div className="xl:w-1/2 flex flex-col gap-2 mt-4">
                  <div className="xl: w-1/2">
                    <b>Preis:{" "}
                      {new Intl.NumberFormat("de-DE", {
                        style: "currency",
                        currency: "EUR",
                      }).format(props.price)}{" "}</b>
                  </div>

                </div>
              </div>
              <div className="xl:w-1/2 flex flex-col">
                <div className="mt-5">Artikelnummern:</div>
                <ul style={{ textAlign: 'left' }}>
                  {props.articles.map((article) => <div> - {article.articleNumber}</div>)}
                </ul>
              </div>
            </div>

            <div className="flex-grow"/>
            <div className="flex justify-end">
              <button
                  className="rounded closeButton px-4 py-2 text-white cursor-pointer"
                  onClick={() => {
                    closeModal();
                  }}
                  onTouchEnd={() => {
                    closeModal();
                  }}
              >
                Schließen
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
