import "./App.css";
import Header from "../organisms/Header";
import GridLayout, {SelectedModule} from "../organisms/GridCanvas";
import {useCallback, useEffect, useState} from "react";
import modules from "../modules.json";
import React from "react";
import env from "../config";
import FlexTab from "../atoms/FlexTab";
import ModuleDetails from "../atoms/ModuleDetails";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HelpModal from "../organisms/HelpModal";
import ModulList from "./ModuleList";
import World from "../atoms/World";
import ArticleList from "./ArticleList";


declare global {
    interface Window {
        threeDView: any;
    }
}

enum View {
    SHOW_MODULE_LIST,
    SHOW_2D,
    SHOW_3D,
    SHOW_ARTICLE_LIST
}

const App = () => {
    let initialModules: SelectedModule[] = [];
    const [selectedModules, setSelectedModules] = useState(initialModules);
    const [index, setIndex] = useState(0);
    const [shareLink, setShareLink] = useState("");
    const [selectedModule, setSelectedModule] = useState(undefined);
    const [helpOpen, setHelpOpen] = useState(false);
    const [currentView, setCurrentView] = useState(View.SHOW_3D);

    useEffect(() => {
        window.addEventListener("build", function () {
            selectedModules.forEach((value) => {
                window.threeDView.move("test" + value.index, {
                    x: value.x,
                    y: value.y,
                    rotation: value.rotation,
                });
            });
        });
    }, [selectedModules]);

    useEffect(() => {
        setShareLink("");
        console.log("clear sharelink");
    }, [selectedModules]);

    const showView = (view: View) => {
        setCurrentView(view);
    }

    const create = (nameID = "", x = 50, y = 10, rotation = 90, idx = null) => {
        let origModule = modules.find((value) => value.moduleId === nameID);
        let smodules = selectedModules;
        let newModule = {
            x: x,
            y: y,
            w: origModule?.gridX ?? 1,
            h: origModule?.gridY ?? 1,
            index: idx ?? index.toString(),
            type: nameID,
            rotation: rotation,
            iconImages: [
                origModule?.iconUrl0 ?? "",
                origModule?.iconUrl90 ?? "",
                origModule?.iconUrl180 ?? "",
                origModule?.iconUrl270 ?? "",
            ],
        };
        smodules.push(newModule);
        setSelectedModules(smodules);
        setIndex(index + 1);
        let rotInc = (newModule.rotation % 180) / 90;
        let mx = newModule.x;
        let my = newModule.y;

        if (rotInc === 0) { // Modul steht länglich 0/180 Grad
            mx = mx + newModule.h / 2;
            my = my + newModule.w / 2;
        } else { // Modul steht auf 270/90 Grad
            mx = mx + newModule.w / 2;
            my = my + newModule.h / 2;
        }
        window.threeDView.createOBJ(
            "test" + newModule.index,
            newModule.type,
            mx,
            my,
            0,
            newModule.rotation
        );
        showView(View.SHOW_2D);
    };

    const update3DView = (layout: SelectedModule[]) => {
        let smodules = selectedModules;
        smodules = smodules.map((module) => {
            let fromLayout = layout.find((value) => value.index === module.index);
            if (fromLayout !== undefined) {
                module.x = fromLayout.x;
                module.y = fromLayout.y;
            }
            return module;
        });
        smodules.forEach((movingModule) => {
            let rotInc = (movingModule.rotation % 180) / 90;
            let x = movingModule.x;
            let y = movingModule.y;

            if (rotInc === 0) { // Modul steht länglich 0/180 Grad
                x = x + movingModule.h / 2;
                y = y + movingModule.w / 2;
            } else { // Modul steht auf 270/90 Grad
                x = x + movingModule.w / 2;
                y = y + movingModule.h / 2;
            }
            const movedModule = {
                x: x,
                y: y,
                rotation: movingModule.rotation,
            };
            window.threeDView.move("test" + movingModule.index, movedModule);
        });
        setSelectedModules(smodules);

        persistConfiguration("last", true, shareLink);
    };

    const onRotate = (moduleId: string) => {
        let smodules = selectedModules;
        smodules = smodules.map((module) => {
            if (module.index === moduleId) {
                module.rotation = module.rotation + 90;
                if (module.rotation >= 360) {
                    module.rotation = 0;
                }
                window.threeDView.move("test" + module.index, {
                    x: module.x,
                    y: module.y,
                    rotation: module.rotation,
                });
            }
            return module;
        });
        setSelectedModules(smodules);
    };

    const onDelete = (moduleId: string) => {
        let smodules = selectedModules;
        let newModules: SelectedModule[] = [];
        smodules.forEach(module => {
            if (module.index !== moduleId) {
                newModules.push(module);
            }

        });
        setSelectedModules(newModules);
        setSelectedModule(undefined);
        window.threeDView.remove("test" + moduleId);
    }

    const onSelectModule = (moduleId: string) => {
        let module = selectedModules.find(module => module.index === moduleId);
        if (module) {
            // @ts-ignore
            setSelectedModule(module);
        }
    }

    const onDuplicate = (moduleId: string) => {
        let module = selectedModules.find(module => module.index === moduleId);
        if (module) {
            // @ts-ignore
            create(module.type);
        }
    }

    const clearScreen = () => {
        setSelectedModules([]);
        setIndex(0);
        setSelectedModule(undefined);
        setHelpOpen(true);
        if (window.threeDView) {
            window.threeDView.clear();
        } else {
            console.debug("window.threeDView is not loaded");
        }
    };

    const persistConfiguration = (
        name: string,
        silent: boolean,
        shareLinkId: string
    ) => {
        let configuration = {
            name: name,
            date: new Date().toISOString(),
            modules: selectedModules,
            shareLink: shareLinkId,
        };
        localStorage.setItem("savedConfigs", JSON.stringify(configuration));
        if (!silent) {
            alert("Konfiguration wurde gespeichert");
        }
    };


    const reviveConfiguration = useCallback((name: string) => {
        let config = JSON.parse(
            localStorage.getItem("savedConfigs") || '{"modules":[],"shareLink":""}'
        );
        clearScreen();

        if (config.modules.length === 0) {
            fetch(
                env.REACT_APP_BASE_URL +
                "share/shareLink/findByRandomIdentifier?randomIdentifier="+
                env.REACT_APP_DEFAULT_MODULES,
                {
                    method: "POST", // *GET, POST, PUT, DELETE, etc.
                    mode: "cors", // no-cors, *cors, same-origin
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: "same-origin", // include, *same-origin, omit
                    headers: {
                        "Content-Type": "application/json",
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: "follow", // manual, *follow, error
                    referrerPolicy: "no-referrer", // body data type must match "Content-Type" header
                }
            )
                .then((response) => response.json(), reason => console.log(reason))
                .then((data) => {
                    if (data.content !== undefined) {
                        localStorage.setItem("savedConfigs", data.content);
                        config = config = JSON.parse(
                            localStorage.getItem("savedConfigs") || '{"modules":[],"shareLink":""}'
                        );
                        if (config.modules.length > 0) {
                            setIndex(
                                Math.max(...config.modules.map((module: any) => parseInt(module.index))) +
                                1
                            );
                        } else {
                            setIndex(0);
                        }

                        if (window.threeDView) {
                            config.modules.forEach((module: any) => {
                                let rotInc = (module.rotation % 180) / 90;
                                let x = module.x;
                                let y = module.y;

                                if (rotInc === 0) { // Modul steht länglich 0/180 Grad
                                    x = x + module.h / 2;
                                    y = y + module.w / 2;
                                } else { // Modul steht auf 270/90 Grad
                                    x = x + module.w / 2;
                                    y = y + module.h / 2;
                                }
                                const movedModule = {
                                    x: x,
                                    y: y,
                                    rotation: module.rotation,
                                };
                                window.threeDView.createOBJ(
                                    "test" + module.index,
                                    module.type,
                                    movedModule.x,
                                    movedModule.y,
                                    0,
                                    movedModule.rotation
                                );
                            });
                            setShareLink(config.shareLink);
                            setSelectedModules(config.modules);
                            //update3DView(config.modules);
                            setHelpOpen(true);
                        }
                    }
                }, reason => console.log(reason));
        } else {

            if (config.modules.length > 0) {
                setIndex(
                    Math.max(...config.modules.map((module: any) => parseInt(module.index))) +
                    1
                );
            } else {
                setIndex(0);
            }

            if (window.threeDView) {
                config.modules.forEach((module: any) => {
                    let rotInc = (module.rotation % 180) / 90;
                    let x = module.x;
                    let y = module.y;

                    if (rotInc === 0) { // Modul steht länglich 0/180 Grad
                        x = x + module.h / 2;
                        y = y + module.w / 2;
                    } else { // Modul steht auf 270/90 Grad
                        x = x + module.w / 2;
                        y = y + module.h / 2;
                    }
                    const movedModule = {
                        x: x,
                        y: y,
                        rotation: module.rotation,
                    };
                    window.threeDView.createOBJ(
                        "test" + module.index,
                        module.type,
                        movedModule.x,
                        movedModule.y,
                        0,
                        movedModule.rotation
                    );
                });
                setShareLink(config.shareLink);
                setSelectedModules(config.modules);
                //update3DView(config.modules);
                setHelpOpen(config.modules.length < 1);
            } else {
                console.log("window.threeDView is not loaded");
            }
        }
    }, []);

    useEffect(() => {
        reviveConfiguration("last");
        window.addEventListener("threeJS_loaded", function () {
            reviveConfiguration("last");
        });
    }, [reviveConfiguration]);

    const buy = () => {
        if (selectedModules.length > 0) {
            let storeIds = selectedModules
                .map((module) => {
                    return modules.find((m) => m.moduleId === module.type);
                })
                .map((module) => module?.storeIds)
                .flat()
                .map((id) => {
                    return {
                        referencedId: id,
                        quantity: 1,
                        type: "product",
                    };
                });
            fetch(env.REACT_APP_SHOP + "store-api/context", {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "sw-access-key": env.SHOP_ACCESS_TOKEN,
                },
            })
                .then((result) => result.json())
                .then((result) =>
                    fetch(env.REACT_APP_SHOP + "store-api/checkout/cart", {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            "sw-access-key": env.SHOP_ACCESS_TOKEN,
                            "sw-context-token": result.token,
                        },
                    })
                )
                .then((result) => result.json())
                .then((result) =>
                    fetch(env.REACT_APP_SHOP + "store-api/checkout/cart/line-item", {
                        method: "post",
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            "sw-access-key": env.SHOP_ACCESS_TOKEN,
                            "sw-context-token": result.token,
                        },
                        body: JSON.stringify({
                            items: storeIds,
                        }),
                    })
                )
                .then((result) => result.json())
                .then((result) =>
                    window.location.assign(
                        env.REACT_APP_SHOP + "change-context/" + result.token
                    )
                );
        }
    };

    const getModuleData = (selectedModule: SelectedModule | undefined) => {
        let moduleData = null;
        if (selectedModule !== undefined) {
            moduleData = modules.find((m) => m.moduleId === selectedModule.type);
        }
        return moduleData;
    }

    return (
        <div className="flex h-full flex-col overflow-hidden">
            <Header
                onClear={clearScreen}
                onLoad={() => reviveConfiguration("last")}
                onSave={() => persistConfiguration("last", false, shareLink)}
                onBuy={buy}
                config={{
                    modules: selectedModules,
                    shareLink: shareLink,
                }}
                onShareLinkChanged={(id: string) => {
                    setShareLink(id);
                    persistConfiguration("last", true, id);
                }}
            />
            <HelpModal modalIsOpen={helpOpen} setOpen={setHelpOpen}/>
            <div className="flex flex-grow flex-col">
                <header
                    className="invisible h-0 sm:visible sm:h-auto overflow-hidden flex flex-row border border-gray-300 bg-gray-100">
                    <div className="xl:w-1/3 flex flex-wrap">
                        <FlexTab text={"Modulauswahl"} onClick={() => {
                            showView(View.SHOW_MODULE_LIST);
                        }} active={currentView === View.SHOW_MODULE_LIST} hover={true}
                                 classes={"xl:w-1/3"}/>
                        <FlexTab text={"2D-Konfigurator"} onClick={() => {
                            showView(View.SHOW_2D);
                        }} active={currentView === View.SHOW_2D} hover={true} classes={"w-auto xl:w-1/3"}/>
                        <FlexTab text={"Bestellliste"} onClick={() => {
                            showView(View.SHOW_ARTICLE_LIST);
                        }} active={currentView === View.SHOW_ARTICLE_LIST} hover={true} classes={"w-auto xl:w-1/3"}/>
                    </div>
                    <div className="xl:w-2/3 flex flex-row">
                        <FlexTab text={"3D-Ansicht"} onClick={() => {
                            showView(View.SHOW_3D);
                        }} active={currentView === View.SHOW_3D} hover={true} classes={""}/>
                        <div className="flex-grow"/>
                        <button className="mx-4" onClick={() => setHelpOpen(true)}><FontAwesomeIcon size={"2x"}
                                                                                                    icon={faInfoCircle}/>
                        </button>
                    </div>
                </header>
                <header className="sm:hidden sm:h-0 overflow-hidden flex flex-row border border-gray-300 bg-gray-100">
                    <div className="w-auto xl:w-1/3 flex flex-wrap">
                        <FlexTab text={"Module"} onClick={() => {
                            showView(View.SHOW_MODULE_LIST);
                        }} active={currentView === View.SHOW_MODULE_LIST} hover={true}
                                 classes={"xl:w-1/3"}/>
                        <FlexTab text={"2D"} onClick={() => {
                            showView(View.SHOW_2D);
                        }} active={currentView === View.SHOW_2D} hover={true} classes={"w-auto xl:w-2/3"}/>
                    </div>
                    <FlexTab text={"3D"} onClick={() => {
                        showView(View.SHOW_3D);
                    }} active={currentView === View.SHOW_3D} hover={true} classes={"w-auto xl:w-2/3"}/>
                    <div className="flex-grow"/>
                    <button className="px-2 mr-2" onClick={() => setHelpOpen(true)}><FontAwesomeIcon size={"2x"}
                                                                                                     icon={faInfoCircle}/>
                    </button>
                </header>
                <div className="flex flex-grow flex-col xl:flex-row">
                    <div
                        className={"xl:block xl:w-1/3" + ((currentView === View.SHOW_2D || currentView === View.SHOW_MODULE_LIST) ? "" : " hidden")}>
                        <div className={"vh_minus_header overflow-y-scroll xl:overflow-auto"}>
                            <ModulList open={currentView === View.SHOW_MODULE_LIST} modules={modules} create={create}/>
                            <ArticleList open={currentView === View.SHOW_ARTICLE_LIST} modules={modules} selectedModules={selectedModules}/>
                            <GridLayout
                                open={currentView === View.SHOW_2D || currentView === View.SHOW_3D}
                                modules={selectedModules}
                                onLayoutChange={(layout: SelectedModule[]) => update3DView(layout)}
                                onSelectModule={onSelectModule}
                            />

                            <ModuleDetails open={(currentView === View.SHOW_2D) || (currentView === View.SHOW_3D)}
                                           module={selectedModule} moduleDetails={getModuleData(selectedModule)}
                                           onDelete={onDelete} onRotate={onRotate} onDuplicate={onDuplicate}/>
                        </div>
                    </div>
                    <World className={
                        "w-full h-full xl:w-2/3 xl:block" + (currentView === View.SHOW_3D ? "" : " hidden")
                    }
                           style={{maxHeight: "calc(100vh - 66px)"}}
                    />
                </div>
            </div>
        </div>
    );
};

export default App;
