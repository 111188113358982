import logo from "./FlexocubeLogo.png";
import segmuellerLogo from "../segmueller_logo.svg";
import jendrassLogo from "../logo_shop_big.png"
import React, {useEffect, useState} from "react";
import "./Header.css";
import HeaderModal from "./HeaderModal";
import env from "../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons/faShoppingCart";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {faSave} from "@fortawesome/free-solid-svg-icons/faSave";
import {faShareAlt} from "@fortawesome/free-solid-svg-icons/faShareAlt";
import {faFolderOpen} from "@fortawesome/free-solid-svg-icons/faFolderOpen";

declare global {
  interface Window {
    importScene: any;
    exportScene: any;
  }
}

export type HeaderProps = {
  onClear: Function;
  onLoad: Function;
  onSave: Function;
  onShareLinkChanged: Function;
  onBuy: Function;
  config: any;
};
export default function Header({
  onClear,
  onLoad,
  onSave, onBuy,
  config,
  onShareLinkChanged,
}: HeaderProps) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [hostUrl, setHostUrl] = useState('');
  const [identifierUrl, setIdentifierUrl] = useState("XDF");

    useEffect(() => {
        const url = window.location.origin;
        setHostUrl(url);
    }, []);

  const openModal = async (config: any) => {
    setIsOpen(true);
    let urlId = config.shareLink;
    if (urlId === "") {
      let data = await fetch(
        env.REACT_APP_BASE_URL + "share/shareLink/request",
        {
          method: "POST",
          body: JSON.stringify({
            validUntil: "2021-08-09",
            name: "",
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((result) => result.json());
      urlId = data.randomIdentifier;
      setIdentifierUrl(env.REACT_APP_BASE_FLEXCUBE + urlId);
      onShareLinkChanged(urlId);
    }
    await fetch(env.REACT_APP_BASE_URL + "share/shareLink/addConfiguration", {
      method: "POST",
      body: JSON.stringify({
        content: JSON.stringify(config),
        randomIdentifier: urlId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((result) => result.json())
      .then((data) => {})
      .catch((error) => console.log(error));
  };

  const closeModal = () => {
    setIsOpen(false);
  };

   return (
    <header className="header border-b-2 border-black" id="topnav">
      <div className="header-container flex flex-wrap">
          <div className="flex flex-row"><a href="https://flexocube.de" target="_blank" rel="noreferrer">
              <img src={logo} className="app-logo" alt="Flexocube" /></a>
            <div className="header-text w-32"><a href="https://flexocube.de" target="_blank" rel="noreferrer">FLEXOCUBE</a></div>
          </div>
          <div className="actions invisible h-0 sm:visible sm:h-auto flex flex-grow">
              <button className="button1" onClick={() => onBuy()}>
                Zum Warenkorb hinzufügen
              </button>
              <button className="button1" onClick={() => openModal(config)}>
                  Konfiguration in App öffnen
              </button>
              <button className="button1" onClick={() => onClear()}>
                  Neu starten
              </button>
              <div className="flex-grow">&nbsp;</div>
              <a className="button1" href="https://flexocube.de/impressum/" target="_blank" rel="noreferrer">
                  Impressum
              </a>
              {hostUrl.includes("segmueller") && (
                  <img src={segmuellerLogo} alt="Logo" width="100px" style={{marginRight:10}} />
              )}
              {hostUrl.includes("jendrass") && (
                  <img src={jendrassLogo} alt="Logo" width="150px" style={{marginTop:5, marginRight:10, marginBottom:5}} />
              )}
          </div>
          <div className="sm:hidden sm:h-0 overflow-hidden flex flex-wrap w-full">
              <button className="flex-none buttonIcon visible sm:invisible" onClick={() => onBuy(config)}>
                  <FontAwesomeIcon size={"2x"} icon={faShoppingCart}/>
              </button>
              <button className="buttonIcon visible sm:invisible" onClick={() => onLoad(config)}>
                  <FontAwesomeIcon size={"2x"} icon={faFolderOpen}/>
              </button>

              <button className="buttonIcon visible sm:invisible" onClick={() => onSave(config)}>
                  <FontAwesomeIcon size={"2x"} icon={faSave}/>
              </button>
              <button className="buttonIcon visible sm:invisible" onClick={() => openModal(config)}>
                  <FontAwesomeIcon size={"2x"} icon={faShareAlt}/>
              </button>
              <div className="flex-grow">
              </div>
              <button className="buttonIcon align-middle visible sm:invisible" onClick={() => onClear(config)}>
                  <FontAwesomeIcon size={"2x"} icon={faTrash}/>
              </button>
          </div>

      </div>
      <HeaderModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        identifierUrl={identifierUrl}
      ></HeaderModal>
    </header>
  );
}
